import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import logo from '../assets/logo2.png';
import profileUser from '../assets/profile-user.png';
import { useAuth } from '../hooks/useAuth';
import './MultipleChatStyle.css';

const MultipleChat = () => {
    const { currentUser } = useAuth();
    const user_id = currentUser.uid;
    const chatEndRef = useRef(null);
    const [message, setMessage] = useState('');
    const textareaRef = useRef(null);
    const [maxLength, setMaxLength] = useState(150);
    const [maxK, setMaxK] = useState(2);
    const [systemPrompt, setSystemPrompt] = useState('You Are Metamind');
    const [summary, setSummary] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false); // Loading state

    const sendMessage = async () => {
        if (!message.trim()) return; // Prevent sending empty messages

        const promptToSend = systemPrompt.trim() ? systemPrompt : 'You are metamindful';
        const prompt = { "role": 'system', "content": promptToSend };
        const userPrompt = { "role": 'user', "content": message };
        const updatedChatHistory = [prompt, ...chatHistory, userPrompt];
        setChatHistory(prev => [...prev, userPrompt]); // Add user's message immediately to chat history
        setMessage('');
        setError(false);
        setLoading(true);

        const dataToSend = {
            "messageTime": new Date().toISOString(),
            "ID": user_id,
            "conversation": updatedChatHistory,
            "max_token": maxLength,
            "k": maxK,
        };

        try {
            const response = await axios.post('https://localserver.airec.tech:8000/generate', dataToSend);

            // Check if response contains an array under the 'response' key
            if (response.data && Array.isArray(response.data.response)) {
                setChatHistory(prev => [
                    ...prev,
                    ...response.data.response.map(content => ({ role: 'assistant', content })) // Map each assistant response
                ]);
            } else {
                // Fallback in case the response format is not as expected
                console.error("Unexpected response format:", response.data);
                setError('Unexpected response format. Please try again.');
            }
        } catch (error) {
            console.error("Error sending message:", error);
            setError('Failed to send message. Please try again.');
        } finally {
            setLoading(false);
        }

        textareaRef.current.style.height = "60px";
    };


    useEffect(() => {
        chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [chatHistory]);

    const handleSystemPromptChange = (e) => setSystemPrompt(e.target.value);

    const handleSubmit = (e) => {
        e.preventDefault();
        sendMessage();
    };

    const handleRangeChange = (event) => setMaxLength(event.target.value);
    const handleKChange = (event) => setMaxK(event.target.value);

    const handleInputChange = (e) => {
        setMessage(e.target.value);
        textareaRef.current.style.height = "auto";
        const scrollHeight = textareaRef.current.scrollHeight;
        const height = Math.min(scrollHeight, 10 * 30);
        textareaRef.current.style.height = height < 60 ? `60px` : `${height}px`;
    };

    return (
        <>
            <div className="chat__container">
                <div className="chat__wrapper">
                    <div className="header__section">
                        <input
                            type="text"
                            placeholder="System Prompt"
                            className="form-control prompt"
                            value={systemPrompt}
                            onChange={handleSystemPromptChange}
                        />
                        <button className="btn btn-info pdf-btn" >Download PDF</button>
                    </div>
                    <div>
                        <label htmlFor="customRange" className="form-label">Max Length: {maxLength}</label>
                        <input
                            type="range"
                            className="form-range"
                            id="customRange"
                            min="0"
                            max="1000"
                            value={maxLength}
                            onChange={handleRangeChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="kRange" className="form-label">K Value: {maxK}</label>
                        <input
                            type="range"
                            className="form-range"
                            id="kRange"
                            min="1"
                            max="10"
                            value={maxK}
                            onChange={handleKChange}
                        />
                    </div>
                    {error ? (
                        <div className="error">{error}</div>
                    ) : (
                        <>
                            <div className="question_answer__wrapper__chatbot">
                                {chatHistory.map((message, i) => (
                                    <div className="single__question__answer" key={i}>
                                        {message.role === 'user' && (
                                            <div className='question_user'>
                                                <div className="left_user_info">
                                                    <img style={{ width: '35px' }} src={profileUser} alt="avatar" />
                                                    <div className="question__user">
                                                        You: {message.content}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {message.role === 'assistant' && (
                                            <div className="answer__area">
                                                <div className="thumbnail">
                                                    <img style={{ width: '40px !important' }} src={logo} alt="avatar" />
                                                </div>
                                                <div className="answer_main__wrapper" style={{ background: '#E2FFC7', padding: '10px', marginTop: '0px', borderRadius: '10px' }}>
                                                    <p className="disc">
                                                        {message.content}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <div ref={chatEndRef}></div>
                                {loading && (
                                    <div className="answer__area">

                                        <div className="answer_main__wrapper" style={{ background: '#E2FFC7', padding: '10px', marginTop: '0px', borderRadius: '10px' }}>
                                            <p className="disc">Loading</p>
                                        </div>
                                    </div>
                                )}
                                {summary.trim() && (
                                    <div className="summary__section">
                                        <h3>Summary:</h3>
                                        <p>{summary}</p>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    <form onSubmit={handleSubmit} className="search-form">
                        <div className="mb-3" style={{ position: 'relative' }}>
                            <textarea
                                ref={textareaRef}
                                className="form-control"
                                style={{
                                    borderRadius: '20px',
                                    background: '#E2FFC7',
                                    width: '100%',
                                    paddingRight: '40px',
                                    resize: 'none',
                                    overflow: 'hidden',
                                    height: '60px',
                                }}
                                id="messageTextarea"
                                placeholder="Ask here"
                                rows="1"
                                value={message}
                                onChange={handleInputChange}
                            ></textarea>
                            <button
                                type="submit"
                                className="send-button"
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    width: '40px',
                                    right: '10px',
                                    transform: 'translateY(-50%)',
                                    border: 'none',
                                    backgroundColor: 'transparent',
                                    cursor: 'pointer',
                                    fontSize: '2.5rem',
                                    color: message.trim() ? 'black' : '#D7D7D7',
                                }}
                                disabled={!message.trim()}
                            >
                                <i className="fa fa-paper-plane" aria-hidden="true"></i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <style jsx>{`

            .send-button:hover {
            color: ${message.trim() ? '#0056b3' : '#D7D7D7'};
        }
        `}</style>
        </>
    );
};

export default MultipleChat;
